//import { CreateVideo, LoadVideo } from './video-component'
//import { CreateOverlay, GetAnimationHTMLComponents } from './overlay-component'
//import GoogleSpreadsheet from 'google-spreadsheet'
//import Analytics from 'analytics'
import { gtag } from './gtag'
import videojs from 'video.js'
import '@videojs/themes/dist/sea/index.css';
export const videoJSPluginName = "tpm-plugin-1ppppqfc-"

var xhttp = new XMLHttpRequest();
xhttp.onreadystatechange = function() {
    if (this.readyState == 4 && this.status == 200) {
        var res = JSON.parse(xhttp.response)
        var url = res.videoSources.html["720p"][0].source;
        var exptime = parseInt(url.substring(url.indexOf("exp=") + 4, url.indexOf("~")))
        var now = new Date().getTime();
        var diff = exptime * 1000 - now
        setTimeout(() => {
            var xhttp2 = new XMLHttpRequest();
            xhttp2.onreadystatechange = function() {
                if (this.readyState == 4 && this.status == 200) {
                    var res2 = JSON.parse(xhttp2.response)
                    document.querySelector("#tpm-plugin-1ppppqfc-").innerHTML += '<source src="' + res2.videoSources.html["720p"][0].source + '" type="video/mp4">'
                }
            }
            xhttp2.open("GET", "https://d.video-cdn.net/play/player/5v3C6R396vqoznNCsBYwCi/video/Eb38uGDyNdeMXaEEtdZFD-", true);
            xhttp2.send();
        }, diff - 5000);
        document.querySelector("#tpm-plugin-1ppppqfc-").innerHTML += '<source src="' + res.videoSources.html["720p"][0].source + '" type="video/mp4">'

        var player = videojs(videoJSPluginName);




        player.ready(function() {
            //idsabling the double tap to fullscreen event on the player
            player.tech_.off('dblclick');
            if (window.givenTime != null) {
                var parsedTime = window.givenTime.split(":");
                var minute = parsedTime.length >= 2 ? parseInt(parsedTime[parsedTime.length - 2]) : 0;
                var hour = parsedTime.length >= 3 ? parseInt(parsedTime[parsedTime.length - 3]) : 0;
                var second = parsedTime.length >= 1 ? parseInt(parsedTime[parsedTime.length - 1]) : 0;
                var seconds = (!isNaN(second)) ? second : 0;
                seconds += (!isNaN(minute)) ? minute * 60 : 0;
                seconds += (!isNaN(hour)) ? hour * 3600 : 0;
                if (seconds != 0) {
                    player.currentTime(seconds);
                }
            }

        });
        document.querySelector("#introVidSkip").addEventListener("click", function() {
            document.querySelector("#introVidContainer").style.display = "none";
            document.querySelector("#introVidContainer").innerHTML = "";
            document.querySelector("#master").setAttribute("data-interacted", "completed");
            player.muted(false);
            changeButtons();
            player.play();
        })


        //gtag('event', 'pageview');

        gtag('event', 'VideoLoaded', {
            'event_category': 'Initialisation',
            'event_label': 'Eular2020FullGerman',
            'value': 'LoadedAndReady'
        });

        window.XMovePlayheadToAndPlay = function(time, panel) {
            var player = videojs(videoJSPluginName);
            player.currentTime(time);
            (document.getElementById(panel)).ToggleOut()
            player.play();
        }



        //videojs(document.querySelector('video')).overlay();
        videojs.getPlayer(videoJSPluginName, {
            plugins: {
                videoJsResolutionSwitcher: {
                    default: 'low', // Default resolution [{Number}, 'low', 'high'],
                    dynamicLabel: true
                }
            }
        }).ready(function() {
            var myPlayer = this,
                textTrack = [{ kind: 'captions', label: 'English', srclang: 'en', src: 'track source goes here' }],
                allTextTacks,
                attLength,
                tt;
            //myPlayer.src("https://videocdn-blob-1.akamaized.net/2hTmtRbesqaR9Jpcxh2Z2A/B2sCe4DTPoQ8uREtsFT13f.aaa828.mp4");
            //myPlayer.src("https://asset-out-cdn.video-cdn.net/private/videos/23CMbBKtjE5C_BamGJJisr/formats/aaa2356?__token__=exp=1594960360~acl=/private/videos/23CMbBKtjE5C_BamGJJisr/formats/aaa2356*~hmac=342812d055b4f9056eac38dccc9e8fcdcb5f460c4fedce1baf65c6e684a39f00");


            myPlayer.on('play', () => {

                if (document.querySelector("#master").getAttribute("data-interacted") != "completed") {

                    myPlayer.pause();
                    myPlayer.muted(false);
                    changeButtons();


                    document.querySelector("#master").setAttribute("data-interacted", "completed");
                }
            });
        }); //end ready

        var isIE = function() {
            const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
            const msie = ua.indexOf('MSIE '); // IE 10 or older
            const trident = ua.indexOf('Trident/'); //IE 11

            return (msie > 0 || trident > 0);
        }
        if (!isIE()) // If Internet Explorer, return version number
        {
            var loadTextDots = 1
            var loadTextChange = setInterval(() => {
                var text = "Interactive content is loading. Please wait";
                for (var i = 0; i < loadTextDots; i++) {
                    text += "."
                }
                if (loadTextDots == 3)
                    loadTextDots = 0;
                loadTextDots++;
                document.querySelector("#intLoadingCont").innerHTML = text;
            }, 400)
            import ('./animation-list').then(({ GetAnimations, arrAnimationComponents }) => {
                const arrAnimationComponentMetadataList = GetAnimations();
                import ('./animation-component-factory').then(({ CreateAnimationComponent }) => {
                    clearInterval(loadTextChange);
                    document.querySelector("#intLoadingCont").style.display = "none";
                    arrAnimationComponentMetadataList.forEach((item) => {
                        const newAnimationComponent = CreateAnimationComponent(item);
                        if (newAnimationComponent != null) {
                            arrAnimationComponents.push(newAnimationComponent)
                        }
                        //newAnimationComponent.div.addEventListener('AnimationTimerTick', ()=>{console.log('Heard It')})
                    });
                    document.addEventListener('AnimationTimerTick', AnimationTimerTick, false)

                    function AnimationTimerTick(e) {
                        //console.log(e)
                        arrAnimationComponents.forEach((animationComponent) => {
                            //console.log(animationComponent)
                            animationComponent.Animate(e)
                        })
                        e.stopPropagation()
                    }

                    window.navigate = (e) => {
                        var index = (arrAnimationComponents.findIndex(x => x.id == "Agreepopup"));
                        var popup = arrAnimationComponents[index];
                        player.pause();
                        popup.TweenIn({ "url": window.event.target.getAttribute("data-href"), "message": "You are leaving BiosimilarsMedicalAcademy.de and entering a website that we dont control.<br/><br/>Biosimilars Medical Academy has provided this link for your convenience, but is not responsible for the content, links, privacy policy, or security of this website." })
                    }
                    window.tweenOut = (e) => {
                        console.log("id " + e)
                        var index = (arrAnimationComponents.findIndex(x => x.id == e));
                        var popup = arrAnimationComponents[index];
                        popup.TweenOut();
                        player.play();

                    }
                    const logtimerevents = false;
                    var globalCurrentTime = 0;
                    player.on('timeupdate', function() {
                        logtimerevents ? console.log(`In standalone on.'timeupdate'`) : null
                        const localcurrentTime = Math.round(player.currentTime())
                        if (Math.round(localcurrentTime) != globalCurrentTime) {
                            globalCurrentTime = localcurrentTime;
                            var event = new CustomEvent("AnimationTimerTick", { "detail": globalCurrentTime });
                            document.dispatchEvent(event);
                        }
                    })
                    if (player.currentTime() >= 2)
                        document.querySelector("#btn_mainMenuToggle").click();
                    player.play();

                    window.addEventListener('resize', function() {
                            var top = document.querySelector("#customoverlay").getBoundingClientRect().height - document.querySelector("#captionsBody").children[0].children[0].getBoundingClientRect().height - 39;
                            document.querySelector("#captionsBody").style.top = top + "px";
                        })
                        //var prvText = "";
                        //p3$(p3sdk.instances).bind("transcript:current_word", function(name, atts) {
                        //    if (prvText != atts.current_word[0].innerText) {
                        //        document.querySelector(".captions").innerHTML = "<h6><span>" + atts.current_word[0].innerText + "</span></h6>";
                        //        if (document.querySelector("#captionsBody").getAttribute("data-docked") == "bottom") {
                        //            var top = document.querySelector("#customoverlay").getBoundingClientRect().height - document.querySelector("#captionsBody").children[0].children[0].getBoundingClientRect().height - 39;
                        //            document.querySelector("#captionsBody").style.top = top + "px";
                        //        }
                        //    }
                        //    prvText = atts.current_word[0].innerText;
                        //})
                    var moveCaptionsDownTimeout = null;
                    var nomovesEvent = function() {
                        if (!document.querySelector(".captions").classList.contains("nomoves")) {
                            if (!document.querySelector("video").paused && !$('.vjs-control-bar').is(":hover"))
                                document.querySelector(".captions").classList += " nomoves";
                            else
                                moveCaptionsDownTimeout = setTimeout(nomovesEvent, 3000);
                        }
                    }
                    var moveCaptionsDown = function() {
                        var docked = document.querySelector(".captions").getAttribute("data-docked");
                        if (docked == "bottom") {
                            if (moveCaptionsDown != null)
                                clearTimeout(moveCaptionsDownTimeout);
                            moveCaptionsDownTimeout = setTimeout(nomovesEvent, 3000);
                            if (document.querySelector(".captions").classList.contains("nomoves"))
                                document.querySelector(".captions").classList.remove("nomoves");
                        } else {
                            if (document.querySelector(".captions").classList.contains("nomoves"))
                                document.querySelector(".captions").classList.remove("nomoves");
                        }
                    }
                    document.querySelector("video").addEventListener("mousemove", moveCaptionsDown);
                    document.querySelector("video").addEventListener("touch", moveCaptionsDown);
                    document.querySelector("video").addEventListener("mouseup", moveCaptionsDown);
                    document.querySelector("video").addEventListener("mousedown", moveCaptionsDown);
                    if (window.givenTime != null || window.openSlides != null || window.openTranscript != null || window.ts != null || window.poll != null) {
                        var indexx = arrAnimationComponents.findIndex(x => x.id == "ButtonGroup");
                        if (indexx != -1) {
                            arrAnimationComponents[indexx].TweenIn();
                        }
                    }
                    if (window.openSlides == "true") {
                        document.querySelector("#btn_slides").click();
                    }
                    if (window.openTranscript == "true") {
                        document.querySelector("#btn_transcript").click();
                    }
                    if (window.ts != null) {
                        var childrens = document.querySelector("#menu_topics").children;
                        for (var n = 0; n < childrens.length; n++) {
                            if (childrens[n].innerHTML.includes(window.ts)) {
                                setTimeout(() => {
                                    document.querySelector("#btn_topics").click();
                                    setTimeout(() => {
                                        childrens[n].click();
                                    }, 1000);
                                }, 2000);
                                break;
                            }
                        }
                    }
                    if (window.poll != null) {
                        var childrens = document.querySelector("#menu_polls").children;
                        for (var n = 0; n < childrens.length; n++) {
                            if (childrens[n].innerHTML.includes(window.poll)) {
                                setTimeout(() => {
                                    document.querySelector("#btn_polls").click();
                                    setTimeout(() => {
                                        childrens[n].click();
                                    }, 1000);
                                }, 2000);
                                break;
                            }
                        }
                    }
                })

            })
        } else {
            document.querySelector("#intLoadingCont").style.display = "none"
            document.querySelector("#introVidContainer").style.display = "none";
            player.play();
            document.querySelector(".introVid").innerHTML = "";
        }
    }
};
xhttp.open("GET", "https://d.video-cdn.net/play/player/5v3C6R396vqoznNCsBYwCi/video/Eb38uGDyNdeMXaEEtdZFD-", true);
xhttp.send();

function changeButtons() {

    var el = document.querySelector('.vjs-fullscreen-control'),
        elClone = el.cloneNode(true);
    //replacing the bull screen button to remove all the players' event
    el.parentNode.replaceChild(elClone, el);
    var elem = document.querySelector(".vjs-subs-caps-button").childNodes[0].childNodes[0].cloneNode(true);
    document.querySelector(".vjs-subs-caps-button").innerHTML = "";
    document.querySelector(".vjs-subs-caps-button").appendChild(elem)
    document.querySelector(".vjs-subs-caps-button").addEventListener("click", () => {
        if (document.querySelector("#captionsBody").classList.contains("inactive")) {
            document.querySelector("#captionsBody").classList.remove("inactive")
                //reloading the content. Chrome sometimes doesnot load the css correctly when element is not visible
            var top = document.querySelector("#customoverlay").getBoundingClientRect().height - document.querySelector("#captionsBody").children[0].children[0].getBoundingClientRect().height - 39;
            document.querySelector("#captionsBody").style.top = top + "px";
            console.log("captionsbody")
            console.log(document.querySelector("#captionsBody").innerHTML)
            if (document.querySelector("#captionsBody").innerHTML == "") {
                document.querySelector("#captionsBody").innerHTML = "<h6><span>Loading...</span></h6>";
            }
        } else {
            document.querySelector("#captionsBody").classList += " inactive";
        }

    });

    //adding the new fullscreen event
    document.querySelector('.vjs-fullscreen-control').addEventListener("click", fullscreenVideo);
}
//New fullscreen event that will replace the current fullscreen event on the players' button
function fullscreenVideo() {
    const masterDiv = document.getElementById("master")

    if (!masterDiv.classList.contains("fullscreened")) {
        //full screening the master div instead of just the player
        document.querySelector("#outercont").requestFullscreen();
        masterDiv.classList += " fullscreened";
    } else {
        document.exitFullscreen();
        masterDiv.classList.remove("fullscreened");
    }
}
window.toggleExpand = function(e) {
    var cardBody = e.parentNode.parentNode.querySelector('.card-body');
    if (cardBody.classList.contains('expanded')) {
        e.innerHTML = "Show More";
        cardBody.classList.remove("expanded");
    } else {
        e.innerHTML = "Show Less";
        cardBody.classList += " expanded";
    }
}